import React, { Component } from 'react';
import { Container, Image, Segment, Dropdown, Card, Header } from 'semantic-ui-react'

import { withFirebase } from '../Firebase';

import MoviesListinYear from '../minicomponents/movieslist.js'

class TeluguMovies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      telugumovies: {},
      selectedyear: new Date().getFullYear().toString(),
      banner : {img_link : '/images/tollywood_banner.jpg'}
    };
  }

  componentDidMount() {
    this.setState({ loading: true });


    this.setState({ loading: true });

    var sortingattr = 'moviename';
    this.props.firebase.movies().child('Telugu').orderByChild(sortingattr).on('value', snapshot => {

      let telugumovies = {}
      snapshot.forEach(child => {
        telugumovies[child.key] = child.val()
      });

      this.setState({
        telugumovies,
        loading: false
      })


    });

    this.props.firebase.rootref().child('website_settings').once('value',snapshot => {
      // console.log("Website Settings",snapshot.val())
      const banner = snapshot.val()['banner']['tollywood_page']
      this.setState({banner})
    })

  }

  componentWillUnmount() {
    this.props.firebase.movies().off();
  }

  render() {
    const { telugumovies, selectedyear } = this.state;

    let years = [
      {
        key: "2023",
        text: "2023",
        value: "2023",
      },
      {
        key: "2022",
        text: "2022",
        value: "2022",
      },
      {
        key: "2021",
        text: "2021",
        value: "2021",
      }, {
        key: "2020",
        text: "2020",
        value: "2020",
      }, {
        key: "2019",
        text: "2019",
        value: "2019",
      }, {
        key: "2018",
        text: "2018",
        value: "2018",
      }, {
        key: "2017",
        text: "2017",
        value: "2017",
      }, {
        key: "2016",
        text: "2016",
        value: "2016",
      }];

    return (
      <Container style={{
        marginTop: 55
      }}>
        {/* <a href="https://cutt.ly/mcXx3ab"> */}
          {/* <Image fluid src='/images/vakeel_saab_banner.jpg' /> */}
        {/* </a> */}
        <Image fluid src={this.state.banner.img_link} as='a' target='_blank' href={this.state.banner.redirect_link} />
        <Segment clearing basic>
          <Header as='h2' floated='left'>
            Tollywood
          </Header>
          <Dropdown style={{ float: "right" }}
            compact
            selection
            name="selectedyear"
            options={years}
            defaultValue={this.state.selectedyear}
            placeholder='Select Year'
            onChange={(_e, { value }) => this.setState({ selectedyear: value })}
          />
        </Segment>
        <h3>{selectedyear}</h3>
        <Card.Group itemsPerRow={5} doubling>
          <MoviesListinYear movies={telugumovies} selectedyear={selectedyear} />
        </Card.Group>
      </Container>
    );
  }
}


export default withFirebase(TeluguMovies);

